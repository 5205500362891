import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				 Xysyboi Golf Course
			</title>
			<meta name={"description"} content={"Embrace the Art of Golf in a Serene Oasis"} />
			<meta property={"og:title"} content={" Xysyboi Golf Course"} />
			<meta property={"og:description"} content={"Embrace the Art of Golf in a Serene Oasis"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/653790b5cd81ee001f7e2823/images/icon.webp?v=2023-10-24T11:08:46.069Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/653790b5cd81ee001f7e2823/images/icon.webp?v=2023-10-24T11:08:46.069Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/653790b5cd81ee001f7e2823/images/icon.webp?v=2023-10-24T11:08:46.069Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/653790b5cd81ee001f7e2823/images/icon.webp?v=2023-10-24T11:08:46.069Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/653790b5cd81ee001f7e2823/images/icon.webp?v=2023-10-24T11:08:46.069Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/653790b5cd81ee001f7e2823/images/icon.webp?v=2023-10-24T11:08:46.069Z"} />
			<meta name={"msapplication-TileColor"} content={"https://uploads.quarkly.io/653790b5cd81ee001f7e2823/images/icon.webp?v=2023-10-24T11:08:46.069Z"} />
		</Helmet>
		<Components.Header />
		<Section background="--color-green" padding="80px 0 80px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Contacts{"  "}
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
					Reserve your tee time or explore our training programs now. At Xysyboi Golf Course, every swing tells a unique story, and your golfing odyssey begins in our serene oasis designed to make your golf dreams come true.
				</Text>
				<Box min-width="100px" min-height="100px" text-align="left">
					<Text margin="0px 0px 16px" font="--headline3" color="--light" text-align="left">
						Location: 5540 Sweetwater Rd, Bonita, CA 91902, US
					</Text>
					<Link
						href="tel:+1 619-267-1103"
						text-align="left"
						color="--light"
						font="--headline3"
						text-decoration-line="initial"
						margin="0px 0px 16px 0px"
						display="block"
					>
						Phone: +1 619-267-1103
					</Link>
					<Link
						href="mailto:blank?contact@xysyboi.com"
						text-align="left"
						color="--light"
						font="--headline3"
						text-decoration-line="initial"
						margin="0px 0px 16px 0px"
						display="block"
					>
						Email: contact@xysyboi.com
					</Link>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});